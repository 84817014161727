import axios from 'axios';
import { API_PATH } from '@/constants/apiPath';
import getOptionAxios from '@/supporter/getOptionAxios';

export const loadVariablesService = () => {
  const options = getOptionAxios(true);
  return axios.get(API_PATH.variables, options);
};

export const loadClientIdService = () => axios.get(API_PATH.clientId);
