import axios from 'axios';
import { API_PATH } from '@/constants/apiPath';

export const getColumnsService = (dbUsername, dbPassword, dbHost, dbName, dbPort, sqlQuery) => {
  const data = {
    db_username: dbUsername,
    db_password: dbPassword,
    db_host: dbHost,
    db_port: dbPort,
    db_name: dbName,
    sql_query: sqlQuery
  };
  return axios.post(API_PATH.sqlColumns, data);
};
