const URL = process.env.REACT_APP_API_URL || '';

export const API_PATH = {
  login: `${URL}/api/auth/login`,
  logout: `${URL}/api/auth/logout`,
  getProfile: `${URL}/api/monday/get-profile`,
  dataConfig: `${URL}/api/data-config`,
  dataConfigDetail: (_id) =>
    `${URL}/api/data-config/${encodeURIComponent(_id)}`,
  dataConfigInfo: (_id) =>
    `${URL}/api/data-config/${encodeURIComponent(_id)}/get-info`,
  startConfig: (_id) =>
    `${URL}/api/data-config/${encodeURIComponent(_id)}/start`,
  stopConfig: (_id) => `${URL}/api/data-config/${encodeURIComponent(_id)}/stop`,
  toggleDebug: (_id) =>
    `${URL}/api/data-config/${encodeURIComponent(_id)}/toggle-debug`,
  updateIsDelete: (_id) =>
    `${URL}/api/data-config/${encodeURIComponent(_id)}/update-delete`,
  mondayToken: `${URL}/api/monday/token`,
  cleanBoard: (boardID) => `${URL}/api/monday/${boardID}/clean-board`,
  mondayBoards: `${URL}/api/monday/boards`,
  mondayGroups: `${URL}/api/monday/groups`,
  mondayColumns: `${URL}/api/monday/columns`,
  sqlColumns: `${URL}/api/sql/columns`,
  ipaddress: `${URL}/api/ipaddress`,
  variables: `${URL}/api/variables`,
  clientId: `${URL}/api/client-id`,
  version: `${URL}/api/version`,
  process: `${URL}/process`,
  columnTypesSupport: `${URL}/api/column-types-support`,
  exportDataConfig: `${URL}/api/export-data-config`,
};
