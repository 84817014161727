import React, { useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { Route, Switch } from 'react-router-dom';

import DefaultLayout from '@/layouts/DefaultLayout';
import Login from '@/pages/Login';
import { verify, handleCallbackRedirect } from '@/actions/auth';
import { loadVariables } from '@/actions/variables';
import '@/assets/scss/global.scss';
import 'jsoneditor/dist/jsoneditor.css';

function App() {
  const dispatch = useDispatch();
  const isLoggingIn = useSelector((state) => state.auth.isLoggingIn);
  useEffect(() => {
    if (!isLoggingIn) dispatch(verify());
  }, [dispatch, isLoggingIn]);

  useEffect(() => {
    dispatch(loadVariables());
  }, [dispatch]);

  useEffect(() => {
    dispatch(handleCallbackRedirect());
  }, [window.location]);

  return (
    <Switch>
      <Route path="/login" exact component={Login} />
      <Route path="/" component={DefaultLayout} />

    </Switch>
  );
}

export default App;
