import {
  GET_MONDAY_COLUMNS,
  GET_GROUPS,
  GET_BOARDS
} from '@/constants/monday';
import { setLoadingAction } from '@/actions/loading';

import { getBoardsService, getGroupsService, getColumnsService } from '@/services/moday';

const getBoardsAction = (data) => ({
  type: GET_BOARDS,
  data
});

const getGroupsAction = (data) => ({
  type: GET_GROUPS,
  data
});

const getColumnsAction = (data) => ({
  type: GET_MONDAY_COLUMNS,
  data
});

export const getBoards = () => (dispatch) => {
  dispatch(setLoadingAction(true));
  return getBoardsService().then((res) => {
    const { data } = res.data;
    const { boards } = data;
    // const selectedBoards = boards.filter((el) => el.state === 'active');
    dispatch(getBoardsAction(boards));
    dispatch(setLoadingAction(false));
    return true;
  }).catch(() => {
    dispatch(setLoadingAction(false));
    return false;
  });
};

export const getGroups = (boardId) => (dispatch) => getGroupsService(boardId).then((res) => {
  const { data } = res.data;
  const { boards } = data;
  const { groups } = boards[0];
  // const selectedGroups = groups.filter((el) => el.deleted !== true && el.archived !== true);
  dispatch(getGroupsAction(groups));
  return true;
}).catch(() => false);

export const getMondayColumns = (boardId) => (dispatch) => getColumnsService(boardId).then((res) => {
  const { data } = res.data;
  const { boards } = data;
  dispatch(getColumnsAction(boards[0].columns));

  return true;
}).catch(() => false);
