import React, { useState } from 'react';
import PropTypes from 'prop-types';
import {
  CInput,
  CInvalidFeedback
} from '@coreui/react';
import { isAlphanumeric } from '@/supporter/validation';

const AlphanumericInput = ({ onChange, msgError, ...props }) => {
  const [valid, setValid] = useState('');

  const change = (e) => {
    const validCron = isAlphanumeric(e.target.value);
    if (validCron) {
      setValid('valid');
    } else {
      setValid('invalid');
    }
    onChange(e);
  };

  return (
    <>
      {/* eslint-disable-next-line */}
      <CInput {...props} onChange={change} valid={valid === 'valid'} invalid={valid === 'invalid'} />
      <CInvalidFeedback className="help-block">
        {msgError}

      </CInvalidFeedback>
    </>
  );
};

AlphanumericInput.propTypes = {
  onChange: PropTypes.func,
  msgError: PropTypes.string
};

AlphanumericInput.defaultProps = {
  onChange: null,
  msgError: ''
};

export default AlphanumericInput;
