import {
  cil3d,
  cilSpeedometer,
  cilSettings,
  cilUser,
  cilHttps,
  cilSync,
  cilPencil,
  cilTrash,
  cilMediaPlay,
  cilMediaStop,
  cilClipboard
} from '@coreui/icons';

export const icons = {
  cil3d,
  cilSpeedometer,
  cilSettings,
  cilUser,
  cilHttps,
  cilSync,
  cilPencil,
  cilTrash,
  cilMediaPlay,
  cilMediaStop,
  cilClipboard
};
