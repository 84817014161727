import React from 'react';
import PropTypes from 'prop-types';
import {
  CModal,
  CModalHeader,
  CModalBody,
  CModalFooter,
  CButton
} from '@coreui/react';

const ErrorModal = ({ message, isOpen, onClose }) => (
  <CModal
    show={isOpen}
    onClose={onClose}
    color="danger"
  >
    <CModalHeader closeButton>Error</CModalHeader>
    <CModalBody style={{ whiteSpace: 'break-spaces' }}>
      {message}
    </CModalBody>
    <CModalFooter>
      <CButton
        color="danger"
        onClick={onClose}
      >
        OK
      </CButton>
    </CModalFooter>
  </CModal>
);

ErrorModal.propTypes = {
  message: PropTypes.oneOfType([PropTypes.string, PropTypes.node]).isRequired,
  isOpen: PropTypes.bool.isRequired,
  onClose: PropTypes.func.isRequired
};

export default ErrorModal;
