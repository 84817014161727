import React, { useEffect, useState } from 'react';
import { useSelector, useDispatch } from 'react-redux';
import {
  CHeader,
  CToggler,
  CHeaderBrand,
  CHeaderNav,
  CHeaderNavItem,
  CHeaderNavLink,
  CSubheader,
  CBreadcrumbRouter,
  CButton,
} from '@coreui/react';

// routes config
import routes from '@/routers';
import { setResponsive } from '@/actions/layout';
import { logout } from '@/actions/auth';
import { getVersionService } from '@/services/version';

const Header = () => {
  const dispatch = useDispatch();
  const [version, setVersion] = useState('');
  const sidebarShow = useSelector((state) => state.layout.sidebarShow);

  const toggleSidebar = () => {
    const val = [true, 'responsive'].includes(sidebarShow) ? false : 'responsive';
    dispatch(setResponsive(val));
  };

  const toggleSidebarMobile = () => {
    const val = [false, 'responsive'].includes(sidebarShow) ? true : 'responsive';
    dispatch(setResponsive(val));
  };

  const onLogout = () => {
    dispatch(logout());
  };

  useEffect(() => {
    getVersionService().then((res) => {
      const { data } = res;
      setVersion(data.version);
    }).catch(() => alert('Can not connect server'));
  }, []);

  const isAuthenticated = useSelector((state) => state.auth.isAuthenticated);
  const renderHeaderItems = () => {
    if (isAuthenticated) {
      return (
        <>
          <span className="mr-2">
            Version
            {' '}
            {version}
          </span>
          <CButton onClick={onLogout}>Logout</CButton>
        </>
      );
    }
    return null;
  };
  return (
    <CHeader withSubheader className="header">
      <CToggler
        inHeader
        className="ml-md-3 d-lg-none"
        onClick={toggleSidebarMobile}
      />
      <CToggler
        inHeader
        className="ml-3 d-md-down-none"
        onClick={toggleSidebar}
      />
      <CHeaderBrand className="mx-auto d-lg-none" to="/">
        <h2 className="m-0 c-sidebar-brand-full">Monday Importer</h2>
      </CHeaderBrand>

      <CHeaderNav className="d-md-down-none mr-auto">
        <CHeaderNavItem className="px-3">
          <CHeaderNavLink to="/">Monday Importer</CHeaderNavLink>
        </CHeaderNavItem>
      </CHeaderNav>
      <CHeaderNav className="px-3">
        {renderHeaderItems()}

      </CHeaderNav>

      <CSubheader className="px-3 justify-content-between">
        <CBreadcrumbRouter
          className="border-0 c-subheader-nav m-0 px-0 px-md-3"
          routes={routes}
        />
      </CSubheader>
    </CHeader>
  );
};

export default Header;
