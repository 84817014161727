import { SET_RESPONSIVE } from '@/constants/layout';

const initialState = {
  sidebarShow: 'responsive'
};

export default (state = initialState, { type, sidebarShow }) => {
  switch (type) {
    case SET_RESPONSIVE:
      return { ...state, sidebarShow };
    default:
      return state;
  }
};
