import axios from 'axios';
import { API_PATH } from '@/constants/apiPath';
import getOptionAxios from '@/supporter/getOptionAxios';

export const loginWithRedirect = (
  clientId,
  redirectUri = window.location.origin

) => {
  window.location = (`https://auth.monday.com/oauth2/authorize?client_id=${clientId}&redirect_uri=${redirectUri}`);
};

export const getMondayAccessToken = (code) => {
  const data = {
    code,
    redirect_uri: window.location.origin
  };
  const options = getOptionAxios(true);
  return axios.post(API_PATH.mondayToken, data, options);
};

export const getBoardsService = () => {
  const options = getOptionAxios(true);
  return axios.get(API_PATH.mondayBoards, options);
};

export const getGroupsService = (boardId) => {
  const data = {
    board_id: boardId
  };
  const options = getOptionAxios(true);
  return axios.post(API_PATH.mondayGroups, data, options);
};

export const getColumnsService = (boardId) => {
  const data = {
    board_id: boardId
  };
  const options = getOptionAxios(true);
  return axios.post(API_PATH.mondayColumns, data, options);
};

export const getProfileService = (apiToken) => {
  const options = getOptionAxios(true, apiToken);
  return axios.get(API_PATH.getProfile, options);
};

export const cleanBoardService = (boardId) => {
  const options = getOptionAxios(true);
  return axios.delete(API_PATH.cleanBoard(boardId), options);
};
