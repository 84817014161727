/* eslint-disable implicit-arrow-linebreak */
import React, { useEffect, useState } from 'react';
import { useSelector, useDispatch } from 'react-redux';
import { useHistory } from 'react-router-dom';
import PropTypes from 'prop-types';
import {
  CCard,
  CCardBody,
  CLabel,
  CButton,
  CInput,
  CForm,
  CRow,
  CCol,
  CInputGroup,
  CTextarea,
  CSelect,
  CInputRadio,
  CFormGroup,
  CInputCheckbox,
} from '@coreui/react';
import Select from 'react-select';
// import CIcon from '@coreui/icons-react';
import { updateDataConfig, addDataConfig } from '@/actions/configMapping';
import { getBoards, getMondayColumns } from '@/actions/monday';
import { getColumnsService as getSQLColumnsService } from '@/services/sql';
import { getColumnTypesSupportService } from '@/services/configMapping';
import { getProfileService } from '@/services/moday';
import Button from '@/components/Button';
import CronInput from '@/components/CronInput';
import AlphanumericInput from '@/components/AlphanumericInput';
import ErrorModal from '@/components/ErrorModal';
import Loading from '@/components/Loading';
import { isAlphanumeric } from '@/supporter/validation';
import { isValidCron } from 'cron-validator';
// import config from '@/supporter/config';

Option.propTypes = {
  data: PropTypes.object.isRequired,
};

const ConfigMappingAction = ({ isUpdate }) => {
  const [isLoading, setLoading] = useState(false);
  const [groupLoading, setGroupLoading] = useState(true);
  const [_id, setId] = useState('');
  const [dbHost, setDBHost] = useState('');
  const [dbPort, setDBPort] = useState('');
  const [dbName, setDBName] = useState('');
  const [dbUsername, setDBUsername] = useState('');
  const [dbPassword, setDBPassword] = useState('');
  const [sqlQuery, setSqlQuery] = useState('');
  const [boardId, setBoardId] = useState();
  const [dataColumns, setDataColumns] = useState([]);
  const [groupId, setGroupId] = useState();
  const [apiToken, setApiToken] = useState('');
  const [dataMapping, setDataMapping] = useState({});
  const [fieldTrack, setFieldTrack] = useState('');
  const [cronExpression, setCronExpression] = useState('');
  const [sqlQueryChange, setChange] = useState(false);
  const [isOpen, setOpen] = useState(false);
  const [errorMessage, setErrorMessage] = useState('');
  const [isDelete, setDelete] = useState(false);

  const [dataColumnTypesSupport, setColumnTypes] = useState([]);

  const dataConfig = useSelector((state) => state.configMapping.dataConfig);
  const dataBoards = useSelector((state) => state.monday.boards);

  const [dataGroups, setDataGroups] = useState([]);
  // const dataGroups = useSelector((state) => state.monday.groups);

  const dataMondayColumns = useSelector((state) => state.monday.columns);
  const variables = useSelector((state) => state.variables.variables);

  const userInfo = useSelector((state) => state.auth.user);

  const accessToken = localStorage.getItem('access_token');

  const dispatch = useDispatch();

  const history = useHistory();

  useEffect(() => {
    if (!isUpdate) {
      setDBHost(variables.dbHost || 'localhost');
      setDBPort(variables.dbPort);
      setDBName(variables.dbName);
      setDBUsername(variables.dbUsername);
      setDBPassword(variables.dbPassword);
      setCronExpression(variables.cronExpression || '');
    }
  }, [isUpdate, variables]);

  useEffect(() => {
    if (isUpdate) {
      const { source, target } = dataConfig;
      setId(dataConfig?.id || '');
      setDataMapping(dataConfig?.dataMapping || {});
      setDelete(dataConfig.is_delete || false);
      if (source) {
        setDBHost(source.dbHost);
        setDBPort(source.dbPort);
        setDBName(source.dbName);
        setDBUsername(source.dbUsername);
        setDBPassword(source.dbPassword);
        setSqlQuery(source.sqlQuery);
        setCronExpression(source.cronExpression);
        setFieldTrack(source.field_track);
      }
      if (target) {
        setBoardId(target.boardId);
        setGroupId(target.groupId);
        setApiToken(target.apiToken);
      }
    }
  }, [isUpdate, dataConfig]);

  useEffect(() => {
    if (apiToken) {
      getProfileService(apiToken)
        .then(() => {
          console.log('success');
        })
        .catch(() => {
          alert(
            'Api token of this config have expired or invalid. Please click save to update new token'
          );
        });
    }
  }, [apiToken]);

  useEffect(() => {
    dispatch(getBoards());
  }, [dispatch]);

  useEffect(() => {
    getColumnTypesSupportService().then((res) => {
      const { data } = res;
      setColumnTypes(data);
    });
  }, []);

  useEffect(() => {
    if (boardId && dataBoards.length > 0) {
      let board = boardId;
      setGroupLoading(true);
      const idx = dataBoards.findIndex((item) => item.id === boardId);

      if (idx === -1) {
        board = dataBoards[0]?.id || '';
        setBoardId(board);
      } else setDataGroups(dataBoards[idx].groups);
      setGroupLoading(false);

      dispatch(getMondayColumns(boardId));
    }
  }, [boardId, dispatch, dataBoards]);

  useEffect(() => {
    if (!isUpdate && dataBoards.length > 0) {
      setBoardId(dataBoards[0].id);
    }
  }, [dataBoards, isUpdate]);

  useEffect(() => {
    if (!isUpdate && dataGroups.length > 0) {
      setGroupId(dataGroups[0].id);
    }
  }, [dataGroups, isUpdate]);

  useEffect(() => {
    setDataColumns(dataMondayColumns);
  }, [dataMondayColumns]);

  const getSQLColumns = () =>
    getSQLColumnsService(
      dbUsername,
      dbPassword,
      dbHost,
      dbName,
      dbPort,
      sqlQuery
    )
      .then((res) => {
        const { data } = res;
        const dataTemp = {};
        for (let i = 0; i < data.length; i += 1) {
          dataTemp[data[i]] = {
            local: data[i],
            monday: {
              id: dataMapping[data[i]]?.monday.id || '',
              title: dataMapping[data[i]]?.monday.title || '',
              type: dataMapping[data[i]]?.monday.type || '',
              settings_str: dataMapping[data[i]]?.monday.settings_str || null,
            },
          };
        }
        setDataMapping({ ...dataTemp });
        setChange(false);
      })
      .catch((err) => {
        let message = 'Can not connect to database';
        const { response } = err;
        if (response?.status === 400) message = response?.data?.detail;
        alert(message);
        setDataMapping({});
        setChange(false);
        setFieldTrack('');
      });

  const setMapping = (key, columnId) => {
    setDataMapping((prevState) => {
      const { ...state } = prevState;
      const index = dataColumns.findIndex((item) => item.id === columnId);
      if (index > -1) {
        state[key].monday = {
          id: dataColumns[index].id,
          title: dataColumns[index].title,
          type: dataColumns[index].type,
          settings_str: dataColumns[index].settings_str || null,
        };
      } else {
        state[key].monday = {
          id: '',
          title: '',
          type: '',
          settings_str: null,
        };
      }
      return state;
    });
  };

  const selectColumn = (sqlColumn, columnId) => {
    setDataColumns((prevState) => {
      const state = [...prevState];
      const idx = state.findIndex((item) => item.sqlColumn === sqlColumn);
      if (idx > -1) {
        state[idx].sqlColumn = null;
      }
      const index = state.findIndex((item) => item.id === columnId);
      if (index > -1) {
        state[index].sqlColumn = sqlColumn;
      }
      return state;
    });
  };

  const selectColumnMonday = (key, columnId) => {
    if (columnId) {
      const columnIdx = dataColumns.findIndex((item) => item.id === columnId);
      const columnType = dataColumns[columnIdx].type;
      const columnName = dataColumns[columnIdx].title;
      const idx = dataColumnTypesSupport.indexOf(columnType);

      if (idx === -1) {
        const columTypeSupportStr = dataColumnTypesSupport.join(', ');
        const msgComponent = (
          <>
            <div>
              {`Column ${columnName} is a ${columnType} type which is not supported. Please either change to another type or map with another column.`}
            </div>
            <div className="font-weight-bold">
              {`Note: Supported column types include ${columTypeSupportStr}`}
            </div>
          </>
        );
        setErrorMessage(msgComponent);
        setOpen(true);
      } else {
        setMapping(key, columnId);
        selectColumn(key, columnId);
      }
    } else {
      setMapping(key, columnId);
      selectColumn(key, columnId);
    }
  };

  const onSubmit = async (e) => {
    e.preventDefault();
    const form = e.target;
    if (form.checkValidity() === false) {
      form.classList.add('was-validated');
    } else {
      if (!isValidCron(cronExpression)) {
        return null;
      }
      if (!isAlphanumeric(_id)) {
        return null;
      }
      if (!fieldTrack) {
        alert('Please choose field track');
        return null;
      }
      if (sqlQueryChange) {
        alert(
          'Your sql query or database setting has changed, please load SQL columns again'
        );
        return null;
      }

      // Get board name

      const source = {
        dbHost,
        dbPort,
        dbName,
        dbUsername,
        dbPassword,
        sqlQuery,
        cronExpression,
        field_track: fieldTrack,
      };
      const target = {
        apiToken: accessToken,
        boardId: boardId.toString(),
        groupId,
      };

      const newDataMapping = {};
      Object.keys(dataMapping).map((item) => {
        if (dataMapping[item].monday.id) {
          newDataMapping[item] = { ...dataMapping[item] };
        }
        return item;
      });

      setLoading(true);
      let result;
      const createBy = userInfo.email;
      if (isUpdate) {
        result = await updateDataConfig(
          _id,
          newDataMapping,
          source,
          target,
          isDelete
        );
      } else {
        result = await addDataConfig(
          _id,
          newDataMapping,
          source,
          target,
          createBy,
          isDelete
        );
      }

      setLoading(false);
      if (result) {
        if (result === true) {
          alert('Success');
          history.push('/config-mapping');
        } else {
          if (result.codeErr === 'board_group_existed') {
            const boardIdx = dataBoards.findIndex(
              (item) => item.id === boardId
            );
            const boardName = dataBoards[boardIdx].name;
            const groupIdx = dataGroups.findIndex(
              (item) => item.id === groupId
            );
            const groupName = dataGroups[groupIdx].title;
            const msgComponent = (
              <>
                <div>
                  {`There is another config that is set with this board [${boardName}] and group [${groupName}]`}
                </div>
                <div>
                  Please select another pair of board/group or modify that
                  existing config to avoid synchronization conflict.
                </div>
              </>
            );
            setErrorMessage(msgComponent);
            setOpen(true);
          } else {
            const messageError = result.detail;
            setErrorMessage(messageError);
            setOpen(true);
          }
        }
      } else {
        const messageError = 'Internal server error';
        setErrorMessage(messageError);
        setOpen(true);
      }
      return null;
    }
    return null;
  };

  const loading = useSelector((state) => state.loading.isLoading);
  if (loading) {
    return (
      <div style={{ height: '100vh' }} className="d-flex align-items-center">
        <Loading className="m-auto" />
      </div>
    );
  }

  const customStylesSelect = {
    container: () => ({
      width: '100%',
    }),
  };

  const onCloseError = () => {
    setOpen(false);
  };

  return (
    <CForm noValidate onSubmit={onSubmit}>
      <CCard>
        <CCardBody>
          <h3 className="font-weight-bold">
            {isUpdate ? 'Update' : 'Add Mapping'}
          </h3>
          <div className="mt-4">
            <CInputGroup className="d-flex align-items-center p-3 border-dash">
              <CLabel className=" mr-3 my-auto label">ID: </CLabel>
              <AlphanumericInput
                required
                disabled={isUpdate}
                value={_id}
                onChange={(e) => setId(e.target.value)}
                msgError={
                  _id
                    ? 'Only letters (a-zA-Z), numbers (0-9), underscores (_), and hyphens (-) are allowed. The first and last character must be letters or numbers. Cannot contains consecutive underscore or hyphens'
                    : 'Please input id'
                }
              />
            </CInputGroup>
            <CInputGroup className="d-flex align-items-center p-3 border-dash mt-3">
              <CLabel className=" mr-3 my-auto label">
                Delete Unmatch Items:
              </CLabel>
              <CInputCheckbox
                className="ml-2 mt-0"
                style={{
                  position: 'unset',
                  height: '20px',
                  width: '20px',
                  cursor: 'pointer',
                }}
                checked={isDelete}
                onChange={(e) => setDelete(e.target.checked)}
              />
            </CInputGroup>

            <CRow className="mt-3">
              <CCol lg="6">
                {/* <CForm noValidate> */}
                <div className="p-3 border-dash">
                  <div className="text-value font-weight-bold mb-3">Source</div>
                  <CInputGroup className="d-flex align-items-center my-3 row">
                    <CCol lg="4" md="5">
                      <CLabel className=" mr-3 my-auto label">
                        Cron Expression:
                      </CLabel>
                    </CCol>
                    <CCol lg="8" md="7" className="p-0">
                      <CronInput
                        required
                        type="text"
                        value={cronExpression}
                        name="cronExpression"
                        onChange={(e) => {
                          setCronExpression(e.target.value);
                        }}
                      />
                    </CCol>
                  </CInputGroup>
                  <CInputGroup className="d-flex align-items-center my-3 row">
                    <CCol lg="4" md="5">
                      <CLabel className=" mr-3 my-auto label">
                        Database Username:
                      </CLabel>
                    </CCol>
                    <CCol lg="8" md="7" className="p-0">
                      <CInput
                        required
                        value={dbUsername}
                        onChange={(e) => {
                          setDBUsername(e.target.value);
                          setChange(true);
                        }}
                      />
                    </CCol>
                  </CInputGroup>
                  <CInputGroup className="d-flex align-items-center my-3 row">
                    <CCol lg="4" md="5">
                      <CLabel className=" mr-3 my-auto label">
                        Database Password:
                      </CLabel>
                    </CCol>
                    <CCol lg="8" md="7" className="p-0">
                      <CInput
                        required
                        value={dbPassword}
                        type="password"
                        onChange={(e) => {
                          setDBPassword(e.target.value);
                          setChange(true);
                        }}
                      />
                    </CCol>
                  </CInputGroup>
                  <CInputGroup className="d-flex align-items-center my-3 row">
                    <CCol lg="4" md="5">
                      <CLabel className=" mr-3 my-Serviceauto label">
                        Database Host:
                      </CLabel>
                    </CCol>
                    <CCol lg="8" md="7" className="p-0">
                      <CInput
                        required
                        value={dbHost}
                        onChange={(e) => {
                          setDBHost(e.target.value);
                          setChange(true);
                        }}
                      />
                    </CCol>
                  </CInputGroup>
                  <CInputGroup className="d-flex align-items-center my-3 row">
                    <CCol lg="4" md="5">
                      <CLabel className=" mr-3 my-auto label">
                        Database Port:
                      </CLabel>
                    </CCol>
                    <CCol lg="8" md="7" className="p-0">
                      <CInput
                        type="number"
                        min={0}
                        max={65536}
                        required
                        value={dbPort}
                        onChange={(e) => {
                          setDBPort(e.target.value);
                          setChange(true);
                        }}
                      />
                    </CCol>
                  </CInputGroup>
                  <CInputGroup className="d-flex align-items-center my-3 row">
                    <CCol lg="4" md="5">
                      <CLabel className=" mr-3 my-auto label">
                        Database Name:
                      </CLabel>
                    </CCol>
                    <CCol lg="8" md="7" className="p-0">
                      <CInput
                        required
                        value={dbName}
                        onChange={(e) => {
                          setDBName(e.target.value);
                          setChange(true);
                        }}
                      />
                    </CCol>
                  </CInputGroup>

                  <CInputGroup className="d-flex align-items-center my-3 row">
                    <CCol lg="4" md="5">
                      <CLabel className=" mr-3 my-auto label">
                        SQL Query:
                      </CLabel>
                    </CCol>
                    <CCol lg="8" md="7" className="p-0">
                      <CTextarea
                        required
                        type="text"
                        value={sqlQuery}
                        onChange={(e) => {
                          setSqlQuery(e.target.value);
                          setChange(true);
                        }}
                      />
                    </CCol>
                  </CInputGroup>
                  <div className="text-right mt-3 mr-3">
                    <Button
                      color="primary"
                      className="font-weight-bold"
                      onClick={getSQLColumns}
                    >
                      Load SQL Columns
                    </Button>
                  </div>
                </div>
                {/* </CForm> */}
              </CCol>
              <CCol lg="6">
                <div className="p-3 border-dash full-h">
                  <div className="text-value font-weight-bold mb-3">Target</div>
                  <CInputGroup className="d-flex align-items-center my-3 row">
                    <CCol lg="3" md="5">
                      <CLabel className=" mr-3 my-auto label">Board: </CLabel>
                    </CCol>
                    <CCol
                      lg="9"
                      md="7"
                      className="p-0 d-flex align-items-center"
                    >
                      <Select
                        value={dataBoards.filter(
                          (option) => option.id === boardId
                        )}
                        onChange={(option) => {
                          setBoardId(option.id);
                        }}
                        options={dataBoards}
                        styles={customStylesSelect}
                        getOptionLabel={(option) =>
                          `${option.name} (${option.id}) [${option.board_kind}] [${option.state}]`
                        }
                        getOptionValue={(option) => option.id}
                        isOptionSelected={(option) => boardId === option.id}
                        placeholder="Please select board ..."
                      />
                    </CCol>
                  </CInputGroup>
                  {groupLoading ? (
                    <Loading className="d-flex justify-content-center" />
                  ) : (
                    <CInputGroup className="d-flex align-items-center my-3 row">
                      <CCol lg="3" md="5">
                        <CLabel className=" mr-3 my-auto label">Group: </CLabel>
                      </CCol>
                      <CCol
                        lg="9"
                        md="7"
                        className="p-0 d-flex align-items-center"
                      >
                        <CSelect
                          custom
                          onChange={(e) => setGroupId(e.target.value)}
                          value={groupId}
                        >
                          {dataGroups.map((item, index) => (
                            <option value={item.id} key={index.toString()}>
                              {item.title}
                            </option>
                          ))}
                        </CSelect>
                      </CCol>
                    </CInputGroup>
                  )}
                </div>
              </CCol>
            </CRow>

            <div className="border-dash full-w p-3 mt-3">
              <div className="text-value font-weight-bold mb-3">
                Data Mapping
              </div>

              <CRow className=" mt-3 ml-auto">
                <CCol md="3">
                  <CLabel>SQL Columns Name</CLabel>
                </CCol>
                <CCol md="6">
                  <CLabel>Monday Columns Name</CLabel>
                </CCol>
                <CCol md="3" className="text-center">
                  <CLabel>Field Track</CLabel>
                </CCol>
              </CRow>
              {Object.keys(dataMapping).map((item, index) => (
                <CRow className=" mt-3 ml-auto" key={index.toString()}>
                  <CCol md="3">
                    <CLabel>{item}</CLabel>
                  </CCol>
                  <CCol md="6">
                    <CSelect
                      custom
                      required={item === fieldTrack}
                      // required
                      onChange={(e) => {
                        selectColumnMonday(item, e.target.value);
                      }}
                      value={dataMapping[item].monday.id}
                    >
                      <option value="">Select data</option>
                      {dataColumns.map((column, idx) => {
                        if (column.sqlColumn && column.sqlColumn !== item) {
                          return null;
                        }
                        return (
                          <option value={column.id} key={idx.toString()}>
                            {`${column.title} - ${column.type}`}
                          </option>
                        );
                      })}
                    </CSelect>
                  </CCol>
                  <CCol md="3">
                    <CFormGroup variant="checkbox" className="text-center">
                      <CInputRadio
                        className="form-check-input"
                        id={item}
                        name="radios"
                        onChange={() => setFieldTrack(item)}
                        onClick={() => setFieldTrack(item)}
                        checked={fieldTrack === item}
                      />
                    </CFormGroup>
                  </CCol>
                </CRow>
              ))}
            </div>
            <div className="text-right mt-3">
              {Object.keys(dataMapping).length > 0 ? (
                <CButton
                  className="font-weight-bold"
                  color="primary"
                  type="submit"
                >
                  {isLoading ? <Loading size={24} /> : 'Save'}
                </CButton>
              ) : null}
            </div>
          </div>
        </CCardBody>
      </CCard>
      <ErrorModal
        message={errorMessage}
        isOpen={isOpen}
        onClose={onCloseError}
      />
    </CForm>
  );
};

ConfigMappingAction.propTypes = {
  isUpdate: PropTypes.bool,
};

ConfigMappingAction.defaultProps = {
  isUpdate: false,
};

export default ConfigMappingAction;
