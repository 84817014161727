const getOptionAxios = (isLogin = false, apiToken = null) => {
  const headers = {
    'Content-Type': 'application/json'
  };
  if (isLogin) {
    const accessToken = localStorage.getItem('access_token');
    headers.Authorization = `Bearer ${accessToken}`;
  }
  if (apiToken) headers.Authorization = `Bearer ${apiToken}`;
  return { headers, withCredentials: true };
};

export default getOptionAxios;
