import ConfigMapping from '@/pages/ConfigMapping';
import AddConfig from '@/pages/AddConfig';
import UpdateConfig from '@/pages/UpdateConfig';

const routes = [
  { path: '/config-mapping', name: 'Config Mapping', component: ConfigMapping, isPrivate: true, exact: true },
  { path: '/config-mapping/:fileName', name: 'Update Mapping', component: UpdateConfig, isPrivate: true, exact: true },
  { path: '/add-config', name: 'Add Config', component: AddConfig, isPrivate: true, exact: true },
];

export default routes;
