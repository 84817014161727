import {
  GET_BOARDS,
  GET_GROUPS,
  GET_MONDAY_COLUMNS
} from '@/constants/monday';

const initialState = {
  boards: [],
  groups: [],
  columns: []
};

export default (state = initialState, action) => {
  switch (action.type) {
    case GET_BOARDS: {
      return {
        ...state,
        boards: [...action.data]
      };
    }
    case GET_GROUPS:
      return {
        ...state,
        groups: [...action.data]
      };
    case GET_MONDAY_COLUMNS: {
      return {
        ...state,
        columns: [...action.data]
      };
    }
    default:
      return state;
  }
};
