import React from 'react';
import { useSelector }from 'react-redux';
import SideBar from '@/layouts/components/SideBar';
import Header from '@/layouts/components/Header';
import Content from '@/layouts/components/Content';
import Footer from '@/layouts/components/Footer';
import Loading from '@/components/Loading';

const DefaultLayout = () => {
  const isVerifying = useSelector((state) => state.auth.isVerifying);
  if (isVerifying) {
    return (
      <div
        style={{ height: '100vh' }}
        className="d-flex align-items-center"
      >
        <Loading className="m-auto" />
      </div>
    );
  }
  return(
    <div className="c-app c-default-layout">
      <SideBar />
      <div className="c-wrapper">
        <Header />
        <div className="c-body">
          <Content />
        </div>

        <Footer />
      </div>

    </div>
  );
};

export default DefaultLayout;
