import React, { useState, useEffect } from 'react';
import PropTypes from 'prop-types';
import { useSelector, useDispatch } from 'react-redux';
import { Link } from 'react-router-dom';
import {
  CRow,
  CCol,
  CButton,
  CCollapse,
  CProgress,
  CSwitch,
} from '@coreui/react';
import CIcon from '@coreui/icons-react';
import Button from '@/components/Button';
import {
  startConfigService,
  stopConfigService,
} from '@/services/configMapping';
import {
  deleteDataConfig,
  getInfoDataConfig,
  toggleDebug,
} from '@/actions/configMapping';
import { cleanBoardService } from '@/services/moday';

const DataMappingItem = ({ item, index, progress }) => {
  const [collapse, setCollapse] = useState(false);
  const [isLoading, setLoading] = useState(false);
  const [boardName, setBoardName] = useState('');
  const [groupName, setGroupName] = useState('');
  const [status, setStatus] = useState(false);
  const [isRuning, setRuning] = useState(false);
  const [debug, setDebug] = useState(false);

  useEffect(() => {
    setRuning(typeof progress === 'number');
  }, [progress]);

  const dispatch = useDispatch();

  const dataBoards = useSelector((state) => state.monday.boards);
  const toggle = (e) => {
    setCollapse(!collapse);
    e.preventDefault();
  };

  const [logs, setLogs] = useState('');

  useEffect(() => {
    setStatus(item.status);
    setDebug(item.debug);
  }, [item]);

  useEffect(() => {
    const { target, groupId } = item;
    const mapBoardAndGroup = (boardId) => {
      const idx = dataBoards.findIndex((value) => value.id === boardId);
      let group = null;
      if (idx > -1) {
        const board = dataBoards[idx];
        const { groups } = board;
        const groupIdx = groups.findIndex((value) => value.id === groupId);
        if (groupIdx > -1) group = groups[groupIdx].title;

        return { board: board.name, group };
      }
      return { board: boardId, group };
    };
    const { board, group } = mapBoardAndGroup(target);
    setGroupName(group || groupId);
    setBoardName(board);
  }, [item, dataBoards]);

  useEffect(() => {
    setLogs(item.logs || '');
  }, [item]);

  const start = (e) => {
    setLoading(true);
    e.preventDefault();
    e.stopPropagation();
    return startConfigService(item.file_name)
      .then((res) => {
        const { data } = res;
        setCollapse(true);
        setLogs(data.detail);
        setStatus(true);
        setLoading(false);
      })
      .catch((err) => {
        const { response } = err;
        setCollapse(true);
        setLogs(response?.data?.error);
        setLoading(false);
        setStatus(false);
      });
  };

  const stop = (e) => {
    e.preventDefault();
    e.stopPropagation();
    if (window.confirm('Do yout want to stop this config')) {
      setLoading(true);
      return stopConfigService(item.file_name)
        .then((res) => {
          const { data } = res;
          setCollapse(true);
          setLogs(data.detail);
          setRuning(false);
          setLoading(false);
          setStatus(false);
        })
        .catch((err) => {
          const { response } = err;
          setCollapse(true);
          setLogs(response?.data?.error);
          setLoading(false);
          setStatus(true);
        });
    }
    return null;
  };

  const deleteItem = (e) => {
    e.preventDefault();
    e.stopPropagation();
    if (window.confirm('Do yout want to delete this item')) {
      setLoading(true);

      return dispatch(deleteDataConfig(item.file_name, index)).then((res) => {
        if (!res) {
          alert('Failed');
        } else {
          if (res !== true) {
            setCollapse(true);
            setLogs(res.error);
          }
        }
        setLoading(false);
      });
    }
    return null;
  };

  const cleanBoard = (e) => {
    e.preventDefault();
    e.stopPropagation();
    if (window.confirm('Do yout want to clean all item on this board')) {
      setLoading(true);

      return cleanBoardService(item.name)
        .then(() => {
          alert('Start cleaning board in background...');
          setCollapse(true);
          setLoading(false);
        })
        .catch((err) => {
          const { response } = err;
          if (response && response.status === 400) {
            alert('Cleaning process is still on RUNNING...');
          } else {
            alert('Can not connect server');
          }
          setLoading(false);
          setCollapse(true);
        });
    }
    return null;
  };

  const reloadDataConfig = () =>
    dispatch(getInfoDataConfig(item.file_name, index)).then((res) => {
      if (!res) alert('Somethings error, please try again');
      return true;
    });

  const toggleDebugMode = (e) => {
    e.preventDefault();
    dispatch(toggleDebug(item.file_name, index, !debug)).then((res) => {
      if (res) setDebug(!debug);
    });
  };

  return (
    <div className="border-dash p-2 mt-2" style={{ minWidth: '500px' }}>
      <CRow
        className="m-0  d-flex align-items-center"
        onClick={toggle}
        style={{ cursor: 'pointer' }}
      >
        <CCol xs="2">{item.name}</CCol>
        <CCol xs="2">{item.source}</CCol>
        <CCol xs="2">{`${boardName} - ${groupName}`}</CCol>
        <CCol xs="1">{status ? 'Running' : 'Idle'}</CCol>
        <CCol xs="2">{item.createBy}</CCol>
        <CCol xs="3" className="d-flex align-items-center">
          <Link to={`/config-mapping/${item.file_name}`}>
            <CButton className="font-weight-bold" disabled={isLoading}>
              <CIcon name="cilPencil" size="lg" />
            </CButton>
          </Link>
          <Button
            className="ml-2"
            disabled={isLoading}
            onClick={(e) => deleteItem(e)}
          >
            <CIcon name="cilTrash" size="lg" />
          </Button>
          <Button
            className="ml-2"
            disabled={isLoading || status || isRuning}
            onClick={(e) => start(e)}
          >
            <CIcon name="cil-media-play" size="lg" />
          </Button>
          <Button className="ml-2" disabled={!status} onClick={(e) => stop(e)}>
            <CIcon name="cil-media-stop" size="lg" />
          </Button>

          <Button
            className="ml-2"
            disabled={isLoading || status || isRuning}
            onClick={(e) => cleanBoard(e)}
            color="danger"
          >
            Clean Board
          </Button>
        </CCol>
      </CRow>
      <CCollapse className="mt-2" show={collapse}>
        <div className="p-3 border-top">
          <div className="d-flex align-items-center">
            <h4 className="m-0">Logs</h4>
            <Button onClick={reloadDataConfig} className="ml-2">
              <CIcon name="cilSync" />
            </Button>
            <div className="d-flex align-items-center">
              <span className="mr-2">Debug</span>
              <CSwitch
                color="success"
                shape="pill"
                checked={debug}
                onChange={toggleDebugMode}
              />
            </div>

            {isRuning ? (
              <CProgress
                value={progress}
                showValue
                animated
                className="ml-4"
                style={{ width: '300px', background: '#f9b115' }}
              />
            ) : null}
          </div>
          <div
            style={{ whiteSpace: 'break-spaces', maxHeight: '500px' }}
            className="p-2 overflow-auto"
          >
            {logs}
          </div>
        </div>
      </CCollapse>
    </div>
  );
};

DataMappingItem.propTypes = {
  item: PropTypes.object.isRequired,
  index: PropTypes.number.isRequired,
  progress: PropTypes.oneOfType([PropTypes.number, PropTypes.string]),
};

DataMappingItem.defaultProps = {
  progress: null,
};

export default DataMappingItem;
