import { SET_LOADING } from '@/constants/loading';

const initialState = {
  isLoading: false
};

export default (state = initialState, { type, isLoading }) => {
  switch (type) {
    case SET_LOADING:
      return { ...state, isLoading };
    default:
      return state;
  }
};
