import {
  GET_LIST_DATA_CONFIG,
  GET_DATA_CONFIG,
  DELETE_DATA_CONFIG,
  UPDATE_DATA_CONFIG
} from '@/constants/configMapping';

const initialState = {
  listDataConfig: [],
  dataConfig: {}
};

export default (state = initialState, action) => {
  switch (action.type) {
    case GET_LIST_DATA_CONFIG:
      return {
        ...state,
        listDataConfig: [...action.data]
      };
    case GET_DATA_CONFIG:
      return {
        ...state,
        dataConfig: { ...action.data }
      };
    case DELETE_DATA_CONFIG: {
      const listDataConfig = [...state.listDataConfig];
      listDataConfig.splice(action.index, 1);
      return {
        ...state,
        listDataConfig
      };
    }
    case UPDATE_DATA_CONFIG: {
      const listDataConfig = [...state.listDataConfig];
      listDataConfig[action.index] = { ...listDataConfig[action.index], ...action.data };
      return {
        ...state,
        listDataConfig
      };
    }

    default:
      return state;
  }
};
