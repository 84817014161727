import React, { useEffect, useState } from 'react';
import { useSelector, useDispatch } from 'react-redux';
import { Link } from 'react-router-dom';
import io from 'socket.io-client';
import { CCard, CCardBody, CRow, CCol } from '@coreui/react';
import { exportDataConfigService } from '@/services/configMapping';
import { getListDataConfig } from '@/actions/configMapping';
import { getBoards } from '@/actions/monday';
import DataMappingItem from '@/components/ConfigMapping/DataMappingItem';
import { API_PATH } from '@/constants/apiPath';
import Button from '@/components/Button';

const ConfigMapping = () => {
  const dispatch = useDispatch();
  const listDataConfig = useSelector(
    (state) => state.configMapping.listDataConfig
  );
  const [progress, setProgress] = useState({});

  useEffect(() => {
    dispatch(getListDataConfig()).then((res) => {
      if (res && res !== true) {
        let message = 'Network error. Can not connect server';
        if (res) message = res?.detail;
        alert(message);
      }
    });
    dispatch(getBoards());
  }, [dispatch]);

  useEffect(() => {
    const socket = io(API_PATH.process);
    socket.on('message', (data) => {
      setProgress((prevState) => {
        const state = { ...prevState };
        return { ...state, ...data };
      });
    });
    return () => socket.close();
  }, []);

  const exportData = () =>
    exportDataConfigService()
      .then((res) => {
        const url = window.URL.createObjectURL(new Blob([res.data]));
        const link = document.createElement('a');
        link.href = url;
        link.setAttribute('download', 'export.csv'); // or any other extension
        document.body.appendChild(link);
        link.click();
      })
      .catch(() => {
        alert('Somethings error, please try again');
      });

  return (
    <CCard>
      <CCardBody>
        <CRow>
          <CCol lg="6">
            <h3>List Config</h3>
          </CCol>
          <CCol lg="6" className="text-right">
            <Button
              color="success"
              className="font-weight-bold mr-4"
              onClick={exportData}
            >
              Export Data
            </Button>
            <Link to="/add-config" className="text-white">
              <div className="btn btn-primary font-weight-bold">Add Config</div>
            </Link>
          </CCol>
        </CRow>
        <div className="mt-4 overflow-auto">
          <CRow style={{ minWidth: '700px' }} className="mx-0 mb-3">
            <CCol xs="2">Config Name</CCol>
            <CCol xs="2">Source</CCol>
            <CCol xs="2">Target</CCol>
            <CCol xs="1">Status</CCol>
            <CCol xs="2">Create By</CCol>
          </CRow>
          {listDataConfig.map((item, index) => (
            <DataMappingItem
              item={item}
              index={index}
              key={index.toString()}
              progress={progress[item.name]}
            />
          ))}
        </div>
      </CCardBody>
    </CCard>
  );
};

export default ConfigMapping;
