import React from 'react';
import {
  Route,
  Switch,
  Redirect
} from 'react-router-dom';
import { CContainer, CFade } from '@coreui/react';
import PrivateRoute from '@/routers/PrivateRoute';

// routes config
import routes from '@/routers';

const Content = () => {
  const redirect = () => <Redirect to="/config-mapping" />;
  return(
    <main className="c-main">
      <CContainer fluid className="full-h">
        <Switch>
          <Route path="/" exact render={redirect} />
          {routes.map((route, idx) => {
            if (route.isPrivate) {
              return (
                <PrivateRoute
                  key={idx.toString()}
                  path={route.path}
                  exact={route.exact}
                  name={route.name}
                  component={route.component}
                />
              );
            }
            return (
              <Route
                key={idx.toString()}
                path={route.path}
                exact={route.exact}
                name={route.name}
                render={(props) => (
                  <CFade className="full-h">
                    {/* eslint-disable-next-line */}
                <route.component {...props} /> 
                  </CFade>
                )}
              />
            );
          })}
        </Switch>

      </CContainer>
    </main>
  );
};
export default React.memo(Content);
