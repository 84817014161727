import { LOAD_VARIABLES, LOAD_CLIENT_ID } from '@/constants/variables';
import { loadVariablesService, loadClientIdService } from '@/services/variables';
import { setLoadingAction } from '@/actions/loading';

const loadVariablesAction = (data) => ({
  type: LOAD_VARIABLES,
  data
});

const loadClientIdAction = (data) => ({
  type: LOAD_CLIENT_ID,
  data
});

export const loadVariables = () => (dispatch) => {
  dispatch(setLoadingAction(true));
  return loadVariablesService().then((res) => {
    const { data } = res;
    dispatch(loadVariablesAction(data));
    dispatch(setLoadingAction(false));
    return true;
  }).catch(() => {
    dispatch(setLoadingAction(false));
    return false;
  });
};

export const loadClientId = () => (dispatch) => {
  dispatch(setLoadingAction(true));
  return loadClientIdService().then((res) => {
    const { data } = res;
    dispatch(loadClientIdAction(data.clientId));
    dispatch(setLoadingAction(false));
    return true;
  }).catch(() => {
    dispatch(setLoadingAction(false));
    return false;
  });
};
