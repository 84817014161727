import React, { useState } from 'react';
import PropTypes from 'prop-types';
import { CInput } from '@coreui/react';
import { isValidCron } from 'cron-validator';

const CronInput = ({ onChange, ...props }) => {
  const [valid, setValid] = useState('');

  const change = (e) => {
    const validCron = isValidCron(e.target.value);
    if (validCron) {
      setValid('valid');
    } else {
      setValid('invalid');
    }
    onChange(e);
  };

  return (
    <CInput
      {...props} // eslint-disable-line
      onChange={change}
      valid={valid === 'valid'}
      invalid={valid === 'invalid'}
    />
  );
};

CronInput.propTypes = {
  onChange: PropTypes.func,
};

CronInput.defaultProps = {
  onChange: null,
};

export default CronInput;
