import { combineReducers } from 'redux';
import { LOGOUT_REQUEST } from '@/constants/auth';
import layoutReducer from '@/reducers/layout';
import authReducer from '@/reducers/auth';
import configMappingReducer from '@/reducers/configMapping';
import loadingReducer from '@/reducers/loading';
import mondayReducer from '@/reducers/monday';
import variablesReducer from '@/reducers/variables';

const appReducer = combineReducers({
  layout: layoutReducer,
  auth: authReducer,
  configMapping: configMappingReducer,
  loading: loadingReducer,
  monday: mondayReducer,
  variables: variablesReducer
});

const rootReducer = (state, action) => {
  let newState = state;
  if (action.type === LOGOUT_REQUEST) {
    newState = undefined;
  }
  return appReducer(newState, action);
};

export default rootReducer;
