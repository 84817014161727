import {
  LOAD_VARIABLES, LOAD_CLIENT_ID
} from '@/constants/variables';

const initialState = {
  variables: {},
  clientId: ''
};

export default (state = initialState, { type, data }) => {
  switch (type) {
    case LOAD_VARIABLES:
      return { ...state, variables: data };
    case LOAD_CLIENT_ID:
      return { ...state, clientId: data };
    default:
      return state;
  }
};
