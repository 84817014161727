import {
  GET_LIST_DATA_CONFIG,
  GET_DATA_CONFIG,
  DELETE_DATA_CONFIG,
  UPDATE_DATA_CONFIG,
} from '@/constants/configMapping';
import { setLoadingAction } from '@/actions/loading';

import {
  getListDataConfigService,
  updateDataconfigservice,
  getDataConfigService,
  deleteConfigMappingService,
  addDataconfigservice,
  getInfoDataConfigService,
  toggleDebugService,
  updateIsDeleteService,
} from '@/services/configMapping';

const getListDataConfigAction = (data) => ({
  type: GET_LIST_DATA_CONFIG,
  data,
});

export const getDataConfigAction = (data) => ({
  type: GET_DATA_CONFIG,
  data,
});

const deleteDataConfigAction = (index) => ({
  type: DELETE_DATA_CONFIG,
  index,
});

const updateDataConfigAction = (index, data) => ({
  type: UPDATE_DATA_CONFIG,
  index,
  data,
});

export const getListDataConfig = () => (dispatch) => {
  dispatch(setLoadingAction(true));
  return getListDataConfigService()
    .then((res) => {
      const { data } = res;
      dispatch(getListDataConfigAction(data));
      dispatch(setLoadingAction(false));
      return true;
    })
    .catch((err) => {
      const { response } = err;
      dispatch(setLoadingAction(false));
      if (response) {
        return response.data;
      }
      return false;
    });
};

export const addDataConfig = (id, dataMapping, source, target, createBy, isDelete) => addDataconfigservice(id, dataMapping, source, target, createBy, isDelete)
  .then(() => true)
  .catch((err) => {
    const { response } = err;
    if (response && (response.status === 400 || response.status === 401)) {
      return response.data;
    }
    return false;
  });

export const updateDataConfig = (id, dataMapping, source, target, isDelete) => updateDataconfigservice(id, dataMapping, source, target, isDelete)
  .then(() => true)
  .catch((err) => {
    const { response } = err;
    if (response && (response.status === 400 || response.status === 401)) {
      return response.data;
    }
    return false;
  });

export const getDataConfig = (fileName) => (dispatch) => {
  dispatch(setLoadingAction(true));
  return getDataConfigService(fileName)
    .then((res) => {
      const { data } = res;
      dispatch(getDataConfigAction(data));
      dispatch(setLoadingAction(false));
      return false;
    })
    .catch((err) => {
      dispatch(setLoadingAction(false));
      return err.response;
    });
};

export const deleteDataConfig = (fileName, index) => (dispatch) => {
  dispatch(setLoadingAction(true));
  return deleteConfigMappingService(fileName)
    .then(() => {
      dispatch(deleteDataConfigAction(index));
      dispatch(setLoadingAction(false));
      return true;
    })
    .catch((err) => {
      const { response } = err;
      dispatch(setLoadingAction(false));
      if (response) {
        return response.data;
      }

      return false;
    });
};

export const getInfoDataConfig = (_id, index) => (dispatch) => getInfoDataConfigService(_id)
  .then((res) => {
    const { data } = res;
    dispatch(updateDataConfigAction(index, data));
    return true;
  })
  .catch(() => false);

export const toggleDebug = (_id, index, debug) => (dispatch) => toggleDebugService(_id, debug)
  .then((res) => {
    const { data } = res;
    dispatch(updateDataConfigAction(index, data));
    return true;
  })
  .catch(() => false);

export const updateIsDelete = (_id, index, isDelete) => (dispatch) => updateIsDeleteService(_id, isDelete)
  .then((res) => {
    const { data } = res;
    dispatch(updateDataConfigAction(index, data));
    return true;
  })
  .catch(() => false);
