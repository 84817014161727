import React from 'react';
import { useSelector, useDispatch } from 'react-redux';
import {
  CCreateElement,
  CSidebar,
  CSidebarBrand,
  CSidebarNav,
  CSidebarNavDivider,
  CSidebarNavTitle,
  CSidebarMinimizer,
  CSidebarNavDropdown,
  CSidebarNavItem,
} from '@coreui/react';

// import CIcon from '@coreui/icons-react';

// sidebar nav config
import navigation from '@/layouts/_nav';
import { setResponsive } from '@/actions/layout';

const Sidebar = () => {
  const dispatch = useDispatch();
  const show = useSelector((state) => state.layout.sidebarShow);

  return (
    <CSidebar
      show={show}
      onShowChange={(val) => dispatch(setResponsive(val))}
    >
      <CSidebarBrand className="d-md-down-none" to="/">
        <h2 className="m-0 c-sidebar-brand-full">MONDAY</h2>
        <h2 className="m-0 c-sidebar-brand-minimized">M</h2>
      </CSidebarBrand>
      <CSidebarNav>

        <CCreateElement
          items={navigation}
          components={{
            CSidebarNavDivider,
            CSidebarNavDropdown,
            CSidebarNavItem,
            CSidebarNavTitle
          }}
        />
      </CSidebarNav>
      <CSidebarMinimizer className="c-d-md-down-none" />
    </CSidebar>
  );
};

export default React.memo(Sidebar);
