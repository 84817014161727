import React from 'react';
import CIcon from '@coreui/icons-react';

export default [
  {
    _tag: 'CSidebarNavItem',
    name: 'Config Mapping',
    to: '/config-mapping',
    icon: <CIcon name="cil-settings" customClasses="c-sidebar-nav-icon" />,
  }
];
