import React from 'react';
import PropTypes from 'prop-types';
import { useSelector } from 'react-redux';
import {
  Route,
  Redirect,
} from 'react-router-dom';
import { CFade } from '@coreui/react';

export default function PrivateRoute({ component: Component, ...rest }) {
  const isAuthenticated = useSelector((state) => state.auth.isAuthenticated);
  return (
    <Route
      {...rest} // eslint-disable-line
      render={(props) => (isAuthenticated ? (
        <CFade className="full-h">
          {/* eslint-disable-next-line */}
            <Component {...props} /> 
        </CFade>
      ) : (
        <Redirect
          to={{
            pathname: '/login',
            state: { from: props.location } // eslint-disable-line
          }}
        />
      ))}
    />
  );
}

PrivateRoute.propTypes = {
  component: PropTypes.func.isRequired,

};
