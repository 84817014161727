import React, { useEffect } from 'react';
import { useSelector, useDispatch } from 'react-redux';
import { Redirect } from 'react-router-dom';
import {
  CCard,
  CCardBody,
  CCardGroup,
  CCol,
  CContainer,
  CRow,
  CButton
} from '@coreui/react';
import Loading from '@/components/Loading';
import { loginWithRedirect } from '@/services/moday';
import { loadClientId } from '@/actions/variables';

const SignIn = () => {
  // const dispatch = useDispatch();
  const isAuthenticated = useSelector((state) => state.auth.isAuthenticated);
  const isLoggingIn = useSelector((state) => state.auth.isLoggingIn);
  const loginError = useSelector((state) => state.auth.loginError);
  const clientId = useSelector((state) => state.variables.clientId);
  const dispatch = useDispatch();

  useEffect(() => {
    dispatch(loadClientId());
  }, [dispatch]);

  if (isAuthenticated) {
    return <Redirect to="/" />;
  }

  const login = () => {
    loginWithRedirect(clientId);
  };

  if (isLoggingIn) {
    return (
      <div
        style={{ height: '100vh' }}
        className="d-flex align-items-center"
      >
        <Loading className="m-auto" />
      </div>
    );
  }
  return (
    <div className="c-app c-default-layout flex-row align-items-center">
      <CContainer>
        <CRow className="justify-content-center">
          <CCol md="8">
            <CCardGroup>
              <CCard className="p-4">
                <CCardBody className="text-center">
                  <h2>Login</h2>
                  {loginError ? <div className="mt-2"><span className="text-danger">Login failed</span></div> : null}
                  <CButton className="mt-4" onClick={login} color="primary">Login with monday</CButton>
                </CCardBody>
              </CCard>
            </CCardGroup>
          </CCol>
        </CRow>
      </CContainer>
    </div>
  );
};

export default SignIn;
