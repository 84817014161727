import {
  LOGIN_REQUEST,
  LOGIN_SUCCESS,
  LOGIN_FAILURE,
  LOGOUT_REQUEST,
  LOGOUT_SUCCESS,
  LOGOUT_FAILURE,
  VERIFY_REQUEST,
  VERIFY_SUCCESS,
  VERIFY_FAILED,
  SET_USER_INFO
} from '@/constants/auth';

const initialState = {
  isLoggingIn: false,
  isLoggingOut: false,
  isVerifying: true,
  loginError: false,
  logoutError: false,
  isAuthenticated: true,
  user: {}
};

export default (state = initialState, action) => {
  switch (action.type) {
    case LOGIN_REQUEST:
      return {
        ...state,
        isLoggingIn: true,
        loginError: false,
      };
    case LOGIN_SUCCESS: {
      return {
        ...state,
        isLoggingIn: false,
        isVerifying: false,
        isAuthenticated: true
      };
    }
    case LOGIN_FAILURE:
      return {
        ...state,
        isLoggingIn: false,
        isAuthenticated: false,
        loginError: true,
        user: {}
      };
    case LOGOUT_REQUEST:
      return {
        ...state,
        isLoggingOut: true,
        logoutError: false,
      };
    case LOGOUT_SUCCESS:
      return {
        ...state,
        isVerifying: false,
        isLoggingOut: false,
        isAuthenticated: false,
        user: {}
      };
    case LOGOUT_FAILURE:
      return {
        ...state,
        isLoggingOut: false,
        logoutError: true,
        user: {}
      };
    case VERIFY_REQUEST:
      return {
        ...state,
        isVerifying: true,
        verifyingError: false,
      };
    case VERIFY_SUCCESS: {
      return {
        ...state,
        isVerifying: false,
      };
    }
    case VERIFY_FAILED: {
      return {
        ...state,
        isAuthenticated: false,
        isVerifying: false,
        user: {}
      };
    }
    case SET_USER_INFO: {
      return {
        ...state,
        user: action.data
      };
    }
    default:
      return state;
  }
};
