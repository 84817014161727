import React, { useEffect, useState } from 'react';
import { useRouteMatch } from 'react-router-dom';
import { useDispatch } from 'react-redux';
import {
  CCard, CCardBody
} from '@coreui/react';
import { getDataConfig, getDataConfigAction } from '@/actions/configMapping';
import ConfigMappingAction from '@/components/ConfigMapping/ConfigMappingAction';

const UpdateConfig = () => {
  const dispatch = useDispatch();
  const match = useRouteMatch();
  const { fileName } = match.params;
  const [notFound, setNotFound] = useState(false);
  useEffect(() => {
    dispatch(getDataConfig(fileName)).then((res) => {
      if (res) {
        if (res.status === 404) {
          setNotFound(true);
        } else {
          alert('Somethings error, please try again');
        }
      }
    });
    return () => {
      dispatch(getDataConfigAction({}));
    };
  }, [dispatch, fileName]);
  if (notFound) {
    return (
      <CCard>
        <CCardBody>
          Not Found
        </CCardBody>
      </CCard>
    );
  }
  return <ConfigMappingAction isUpdate />;
};

export default UpdateConfig;
