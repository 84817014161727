import React, { useState } from 'react';
import PropTypes from 'prop-types';
import {
  CButton
} from '@coreui/react';
import Loading from '@/components/Loading';

const Button = ({ children, onClick, disabled, ...props }) => {
  const [isLoading, setLoading] = useState(false);
  const click = async (e) => {
    if (onClick) {
      setLoading(true);
      await onClick(e);
      setLoading(false);
    }
  };
  return (
    //   eslint-disable-next-line
    <CButton onClick={click} {...props} disabled={isLoading || disabled}> 
      {isLoading ? <Loading size={24} /> : children}
    </CButton>
  );
};

Button.propTypes = {
  children: PropTypes.oneOfType([PropTypes.node, PropTypes.string]),
  onClick: PropTypes.func,
  disabled: PropTypes.bool
};

Button.defaultProps = {
  onClick: null,
  children: null,
  disabled: false
};

export default Button;
