import React from 'react';
import PropTypes from 'prop-types';
import PuffLoader from 'react-spinners/PuffLoader';
import classNames from 'classnames';

const Loading = ({ className, size }) => {
  const classes = classNames(
    'loading',
    className
  );
  return (
    <div className={classes}>
      <PuffLoader
        color="rgb(54, 215, 183)"
        loading
        size={size}
      />
    </div>
  );
};

Loading.propTypes = {
  className: PropTypes.string,
  size: PropTypes.number
};

Loading.defaultProps = {
  className: '',
  size: 60
};

export default Loading;
