import axios from 'axios';
import { API_PATH } from '@/constants/apiPath';
import getOptionAxios from '@/supporter/getOptionAxios';

export const getListDataConfigService = () => {
  const options = getOptionAxios(true);
  return axios.get(API_PATH.dataConfig, options);
};

export const addDataconfigservice = (
  id,
  dataMapping,
  source,
  target,
  createBy,
  isDelete
) => {
  const data = {
    id,
    dataMapping,
    source,
    target,
    createBy,
    is_delete: isDelete,
  };
  const options = getOptionAxios(true);
  return axios.post(API_PATH.dataConfig, data, options);
};

export const updateDataconfigservice = (
  id,
  dataMapping,
  source,
  target,
  isDelete
) => {
  const data = {
    id,
    dataMapping,
    source,
    target,
    is_delete: isDelete,
  };
  const options = getOptionAxios(true);
  return axios.put(API_PATH.dataConfigDetail(id), data, options);
};

export const getDataConfigService = (fileName) => {
  const options = getOptionAxios(true);
  return axios.get(API_PATH.dataConfigDetail(fileName), options);
};

export const deleteConfigMappingService = (fileName) => {
  const options = getOptionAxios(true);
  return axios.delete(API_PATH.dataConfigDetail(fileName), options);
};

export const startConfigService = (fileName) => {
  const options = getOptionAxios(true);
  return axios.get(API_PATH.startConfig(fileName), options);
};

export const stopConfigService = (fileName) => {
  const options = getOptionAxios(true);
  return axios.get(API_PATH.stopConfig(fileName), options);
};

export const getIpaddressService = () => {
  const options = getOptionAxios(true);
  return axios.get(API_PATH.ipaddress, options);
};

export const getInfoDataConfigService = (_id) => {
  const options = getOptionAxios(true);
  return axios.get(API_PATH.dataConfigInfo(_id), options);
};

export const toggleDebugService = (_id, debug) => {
  const options = getOptionAxios(true);
  const data = {
    debug,
  };
  return axios.post(API_PATH.toggleDebug(_id), data, options);
};

export const updateIsDeleteService = (_id, isDelete) => {
  const options = getOptionAxios(true);
  const data = {
    is_delete: isDelete,
  };
  return axios.post(API_PATH.updateIsDelete(_id), data, options);
};

export const getColumnTypesSupportService = () => {
  const options = getOptionAxios(true);
  return axios.get(API_PATH.columnTypesSupport, options);
};

export const exportDataConfigService = () => {
  const options = getOptionAxios(true);
  options.responseType = 'blob';
  return axios.get(API_PATH.exportDataConfig, options);
};
