import React from 'react';
import ReactDOM from 'react-dom';
import reduxThunk from 'redux-thunk';
import { Provider } from 'react-redux';
import { applyMiddleware, createStore } from 'redux';
import { BrowserRouter as Router } from 'react-router-dom';
// import { ToastProvider } from 'react-toast-notifications';
import '@/index.css';
import App from '@/App';
import reducer from '@/reducers';
import * as serviceWorker from '@/serviceWorker';
import { icons } from '@/assets/icons';

// Apply middleware for createStore
const createStoreWithMiddleware = applyMiddleware(reduxThunk)(createStore);
const store = createStoreWithMiddleware(
  reducer
  // window.__REDUX_DEVTOOLS_EXTENSION__ && // eslint-disable-line
  //   window.__REDUX_DEVTOOLS_EXTENSION__({ trace: true, traceLimit: 25 }) // eslint-disable-line
);

React.icons = icons;

ReactDOM.render(
  <Provider store={store}>
    {/* <ToastProvider placement="bottom-right" autoDismiss={5} autoDismissTimeout={2000}> */}
    <Router>
      <App />
    </Router>
    {/* </ToastProvider> */}
  </Provider>,
  document.getElementById('root')
);

// If you want your app to work offline and load faster, you can change
// unregister() to register() below. Note this comes with some pitfalls.
// Learn more about service workers: https://bit.ly/CRA-PWA
serviceWorker.unregister();
