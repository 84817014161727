import {
  LOGIN_REQUEST,
  LOGIN_SUCCESS,
  LOGIN_FAILURE,
  LOGOUT_REQUEST,
  LOGOUT_SUCCESS,
  LOGOUT_FAILURE,
  VERIFY_REQUEST,
  VERIFY_SUCCESS,
  VERIFY_FAILED,
  SET_USER_INFO
} from '@/constants/auth';

import { getMondayAccessToken, getProfileService } from '@/services/moday';

const DEFAULT_REDIRECT_CALLBACK = () => window.history.replaceState({}, document.title, window.location.pathname);

const requestLoginAction = () => ({
  type: LOGIN_REQUEST,
});

const receiveLoginAction = (user, isAdmin) => ({
  type: LOGIN_SUCCESS,
  user,
  isAdmin,
});

const loginErrorAction = () => ({
  type: LOGIN_FAILURE,
});

const requestLogoutAction = () => ({
  type: LOGOUT_REQUEST,
});

const receiveLogoutAction = () => ({
  type: LOGOUT_SUCCESS,
});

const logoutErrorAction = () => ({
  type: LOGOUT_FAILURE,
});

const verifyRequestAction = () => ({
  type: VERIFY_REQUEST,
});

const verifySuccessAction = () => ({
  type: VERIFY_SUCCESS,
});

const verifyFailedAction = () => ({
  type: VERIFY_FAILED,
});

const setUserInfoAction = (data) => ({
  type: SET_USER_INFO,
  data
});

export const handleCallbackRedirect = (callback = DEFAULT_REDIRECT_CALLBACK) => (dispatch) => {
  try {
    if (
      window.location.search.includes('code=')
    ) {
      dispatch(requestLoginAction());
      const urlParams = new URLSearchParams(window.location.search);
      const code = urlParams.get('code');
      localStorage.setItem('code', code);
      getMondayAccessToken(code).then((res) => {
        const { data } = res;
        const accessToken = data.access_token;
        localStorage.setItem('access_token', accessToken);

        callback();
        dispatch(receiveLoginAction());
      }).catch(() => {
        dispatch(loginErrorAction());
      });
    }
  } catch (e) {
    dispatch(loginErrorAction());
    window.history.push('/login');
  }
};

export const verify = () => (dispatch) => {
  dispatch(verifyRequestAction());
  return getProfileService().then((res) => {
    const { data } = res.data;
    dispatch(receiveLoginAction());
    dispatch(verifySuccessAction());
    dispatch(setUserInfoAction(data.me));
  }).catch(() => {
    dispatch(verifyFailedAction());
  });
};

export const logout = () => (dispatch) => {
  dispatch(requestLogoutAction());
  localStorage.removeItem('access_token');
  dispatch(receiveLogoutAction());
};
